/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const AuthProvider = ({ children }) => {
  const { isAuthenticated, getAccessTokenSilently, isLoading, user } = useAuth0();
  const [emailSaved, setEmailSaved] = useState(false);

  useEffect(() => {
    const initializeUser = async () => {
      if (isAuthenticated && !isLoading && user?.email && !emailSaved) {
        try {
          const token = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || 'https://api.theaissist.com',
            scope: 'openid profile email'
          });
          
          localStorage.setItem('authToken', token);

          try {
            const response = await fetch('https://api.theaissist.com/save-email', {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              }
            });

            if (response.ok) {
              console.log('Email saved successfully');
              setEmailSaved(true);
            } else {
              const errorData = await response.json();
              console.error('Failed to save email:', errorData);
              
              // Fallback to original OAuth flow if needed
              const loginResponse = await fetch('https://api.theaissist.com/login', {
                headers: {
                  'Authorization': `Bearer ${token}`
                },
                credentials: 'include'
              });

              if (loginResponse.ok) {
                console.log('Email saved through OAuth flow');
                setEmailSaved(true);
              } else {
                console.error('Both save attempts failed');
              }
            }
          } catch (error) {
            console.error('Error saving email:', error);
          }
        } catch (error) {
          console.error('Error during authentication:', error);
          localStorage.removeItem('authToken');
        }
      }
    };

    initializeUser();
  }, [isAuthenticated, isLoading, getAccessTokenSilently, user, emailSaved]);

  useEffect(() => {
    if (!isAuthenticated) {
      setEmailSaved(false);
    }
  }, [isAuthenticated]);

  return children;
};

export default AuthProvider; 