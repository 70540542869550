import React, { useState, useRef, useEffect } from 'react';
import { FaPause, FaChevronLeft, FaFileAlt, FaEdit, FaSave } from 'react-icons/fa';
import { FiCopy } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { kill } from '../features/killSlice';
import { summary } from '../features/summarySlice';
import { viewResearch } from '../features/viewResearchSlice';
import { logEdit } from '../features/logEditSlice';
import { chatMessages } from '../features/chatMessagesSlice';

const ViewResearch = () => {
  const { loading, data: viewResearchResponse } = useSelector(state => state.viewResearch) || {};
  const dispatch = useDispatch();

  const [aliceBusy, setAliceBusy] = useState(false);
  const [killDone, setKillDone] = useState(false);
  const [researchText, setResearchText] = useState('');
  const [editable, setEditable] = useState(false);
  const textContainerRef = useRef(null);
  const navigate = useNavigate();

  const { name = '', path = '' } = useSelector(state => state.namePath);
  const { data: viewResearchPath } = useSelector(state => state.createQuestion) || {};
  const { data: chatMessagesResponse } = useSelector(state => state.chatMessages) || {};

  useEffect(() => {
    const pollStatus = () => {
      dispatch(chatMessages());
    };

    // Initial poll
    pollStatus();

    // Set up polling interval
    const intervalId = setInterval(pollStatus, 2000);

    // Cleanup
    return () => clearInterval(intervalId);
  }, [dispatch]);

  useEffect(() => {
    setAliceBusy(chatMessagesResponse?.status?.summary_busy || false);
  }, [chatMessagesResponse?.status]);

  useEffect(() => {
    setResearchText(viewResearchResponse);
  }, [viewResearchResponse]);

  const handleKill = () => {
    dispatch(kill());
    setKillDone(true);
  };

  const handleScrollLeft = () => {
    navigate('/home');
  };

  const handleSummarize = () => {
    dispatch(summary({ name, path }));
    const researchPath = viewResearchPath || path;
    dispatch(viewResearch({ viewResearchPath: researchPath }));
  };

  const toggleEdit = () => {
    setEditable(!editable);
    if(editable){
      dispatch(logEdit({path: viewResearchPath || path, content:researchText}))
    }
  };

  const handleTextChange = (e) => {
    setResearchText(e.target.value);
  };

  const handleTextCopy = () => {
    navigator.clipboard.writeText(researchText)
  }

  return (
    <div className="flex flex-col h-screen bg-background text-text">
      <header className="flex justify-between items-center p-4 bg-background-light">
        <button
          className="flex items-center bg-primary text-text font-semibold px-4 py-2 rounded-lg shadow-md transition-all duration-300 hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary"
          onClick={handleScrollLeft}
        >
          <FaChevronLeft className="mr-2" />
          Back to Home
        </button>
        <button
          className={`bg-secondary text-text font-semibold px-4 py-2 rounded-lg shadow-md transition-all duration-300 hover:bg-secondary-dark focus:outline-none focus:ring-2 focus:ring-secondary ${
            editable ? 'bg-secondary-dark' : ''
          }`}
          onClick={toggleEdit}
        >
          {editable ? (
            <>
              <FaSave className="inline-block mr-2" /> Save
            </>
          ) : (
            <>
              <FaEdit className="inline-block mr-2" /> Edit
            </>
          )}
        </button>
      </header>
      <main className="flex-grow p-6 overflow-auto">
        <div
          className="w-full border border-background-lighter p-6 mb-6 overflow-x-auto bg-background-light rounded-lg shadow-md"
          style={{ height: 'calc(100% - 140px)' }}
        >
          {loading ? (
            <div className="flex justify-center items-center h-full">
              <div className="spinner"></div>
            </div>
          ) : editable ? (
            <textarea
              ref={textContainerRef}
              className="w-full h-full bg-background-lighter text-text border-none rounded p-4 focus:outline-none focus:ring-2 focus:ring-primary font-mono text-sm"
              value={researchText}
              onChange={handleTextChange}
            />
          ) : (
            <p ref={textContainerRef} className="whitespace-pre-line text-text leading-relaxed">
              {researchText}
            </p>
          )}
        </div>
        <div className="flex justify-between">
          <button
            className={`btn ${
              killDone || !aliceBusy ? 'opacity-50 cursor-not-allowed' : 'hover:bg-red-700'
            }`}
            onClick={handleKill}
            disabled={killDone || !aliceBusy}
          >
            <FaPause className="mr-2" />
            Kill
          </button>
          <button
            className="btn"
            onClick={handleTextCopy}
          >
            <FiCopy className="inline-block mr-2" /> Copy Research
          </button>
          <button
            className={`btn-secondary ${
              aliceBusy ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handleSummarize}
            disabled={aliceBusy}
          >
            <FaFileAlt className="mr-2" />
            Produce Report
          </button>
        </div>
      </main>
    </div>
  );
};

export default ViewResearch;
