import React, { useState, useEffect } from "react";
import { FaHome, FaUserCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AnalysisCard from "./AnalysisCard";
import { postPlans } from "../features/postPlanSlice";
import { getPlans } from "../features/getPlanSlice"; // Import getPlans action

const Plans = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleHome = () => {
    navigate("/home");
  };

  const getCurrentTimestamp = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };

  const { data: result } = useSelector((state) => state.getPlans);

  const extractSteps = (input) => {
    const stepsArray = (input || []).map((step) => step.trim());
    return stepsArray.map((step) => step.replace(/^Step \d+:\s*/, ""));
  };

  const [updatedSteps, setUpdatedSteps] = useState([]);
  const [updatedProgress, setUpdatedProgress] = useState("");
  const [updatedTitle, setUpdatedTitle] = useState("");
  const [originalPlan, setOriginalPlan] = useState(null);

  useEffect(() => {
    if (result && result.length > 0) {
      const currentPlan = result[0]; // Assuming we're working with the first plan
      setOriginalPlan(currentPlan);
      setUpdatedTitle(currentPlan.title);
      setUpdatedProgress(currentPlan.progress.replace(/^Step /, ''));
      setUpdatedSteps(extractSteps(currentPlan.steps));
    }
  }, [result]);

  const isPlanModified = () => {
    if (!originalPlan) return false;
    
    const originalSteps = extractSteps(originalPlan.steps);
    const originalProgress = originalPlan.progress.replace(/^Step /, '');

    return (
      updatedTitle !== originalPlan.title ||
      updatedProgress !== originalProgress ||
      JSON.stringify(updatedSteps) !== JSON.stringify(originalSteps)
    );
  };

  const handleSubmit = async () => {
    console.log("handleSubmit called");
    console.log("updatedSteps:", updatedSteps);
    console.log("updatedProgress:", updatedProgress);

    if (isPlanModified() && updatedSteps?.length && updatedProgress) {
      console.log("Plan modified, preparing to dispatch");
      const updatedStepsWithStep = (updatedSteps || []).map(
        (step, index) => `Step ${index + 1}: ${step}`
      );

      console.log("Dispatching postPlans");
      try {
        await dispatch(
          postPlans({
            res: {
              plan: {
                id: originalPlan.id,
                progress: `Step ${updatedProgress}`,
                steps: updatedStepsWithStep,
                title: updatedTitle,
                timestamp: getCurrentTimestamp(),
              },
            },
          })
        ).unwrap(); // Use .unwrap() to handle the promise

        console.log("Plan updated successfully, refetching plans");
        await dispatch(getPlans()); // Refetch plans after successful update
      } catch (error) {
        console.error("Failed to update plan:", error);
        // You might want to show an error message to the user here
      }
    } else {
      console.log("No modifications or conditions not met for dispatch");
    }
    console.log("Navigating to /home");
    navigate("/home");
  };

  return (
    <div className="flex flex-col h-screen bg-background text-text">
      <header className="flex justify-between items-center p-4 bg-background-light">
        <button
          className="flex items-center bg-primary text-text font-semibold px-4 py-2 rounded-lg shadow-md transition-all duration-300 hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary"
          onClick={handleHome}
        >
          <FaHome className="mr-2" />
          Home
        </button>
        <div className="flex items-center space-x-4">
          <button
            onClick={handleSubmit}
            className="bg-secondary text-text font-semibold px-4 py-2 rounded-lg shadow-md hover:bg-secondary-dark focus:outline-none focus:ring-2 focus:ring-secondary transition-all duration-300"
          >
            Submit
          </button>
          <FaUserCircle className="h-8 w-8 text-primary" />
        </div>
      </header>
      <main className="flex-grow p-6 overflow-auto bg-background">
        {(result || []).map((item, index) => (
          <AnalysisCard
            key={index}
            title={item.title}
            news={item.news}
            steps={extractSteps(item?.steps)}
            progress={item.progress}
            timestamp={item.timestamp}
            setUpdatedSteps={setUpdatedSteps}
            setUpdatedProgress={setUpdatedProgress}
            setUpdatedTitle={setUpdatedTitle}
          />
        ))}
      </main>
    </div>
  );
};

export default Plans;
