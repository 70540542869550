import React, { useState } from 'react';
import { FaPause, FaChevronLeft, FaExternalLinkAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { kill } from '../features/killSlice';

const ViewAnalysis = () => {
  const { loading, data: viewAnalysisResponse } = useSelector(state => state.viewAnalysis) || {};
  console.log(viewAnalysisResponse);
  const dispatch = useDispatch();
  const [killDone, setKillDone] = useState(false);

  const iframeContent = viewAnalysisResponse;

  const navigate = useNavigate();

  const handleKill = () => {
    dispatch(kill());
    setKillDone(true);
  };

  const handleScrollLeft = () => {
    navigate('/home');
  };

  const openInNewTab = () => {
    const newWindow = window.open('', '_blank');
    newWindow.document.write(`
      <html>
        <head>
          <style>
            body { 
              font-family: Arial, sans-serif; 
              margin: 20px; 
              background-color: #f8f9fa; 
              color: #333; 
            }
          </style>
        </head>
        <body>${iframeContent}</body>
      </html>
    `);
    newWindow.document.close();
  };

  return (
    <div className="flex flex-col items-center p-6 bg-background text-text">
      <div className="flex justify-between w-full max-w-4xl mt-4">
        <button
          className="bg-primary hover:bg-primary-dark text-text font-bold py-2 px-4 rounded-lg transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-primary"
          onClick={handleScrollLeft}
        >
          <FaChevronLeft className="mr-2" /> Back
        </button>
        <button
          className="bg-secondary hover:bg-secondary-dark text-text font-bold py-2 px-4 rounded-lg transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-secondary"
          onClick={openInNewTab}
        >
          <FaExternalLinkAlt className="mr-2" /> Open in New Tab
        </button>
      </div>
      <div
        className="w-full max-w-4xl border border-background-lighter p-4 mb-4 overflow-x-auto bg-gray-100 rounded-lg shadow-md"
        style={{ maxHeight: '700px' }} 
      >
        {loading ? (
          <p>Loading...</p>
        ) : (
          <iframe
            srcDoc={`
              <html>
                <head>
                  <style>
                    body { 
                      font-family: Arial, sans-serif; 
                      margin: 20px; 
                      background-color: #f8f9fa; 
                      color: #333; 
                    }
                  </style>
                </head>
                <body>${iframeContent}</body>
              </html>
            `}
            className="w-full h-full"
            style={{ border: 'none', minHeight: '600px' }}
            title="Research Content"
          />
        )}
      </div>
      <div className="flex justify-between w-full max-w-4xl">
        <button
          className={`${
            killDone ? 'bg-background-lighter cursor-not-allowed' : 'bg-red-600 hover:bg-red-700'
          } text-text font-bold py-2 px-4 rounded-lg flex items-center transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-red-500`}
          onClick={handleKill}
          disabled={killDone}
        >
          <FaPause className="mr-2" />
          Kill
        </button>
      </div>
    </div>
  );
};

export default ViewAnalysis;
