export const baseUrl = "https://api.theaissist.com";

export const logsApi = `${baseUrl}/logs`;

export const questionLogsApi = `${baseUrl}/question_logs`;

export const killApi = `${baseUrl}/kill`;

export const messagesApi = `${baseUrl}/messages`;

export const questionMessagesApi = `${baseUrl}/question_messages`;

export const promptApi = `${baseUrl}/prompt`;

export const questionApi = `${baseUrl}/question`;

export const summaryApi = `${baseUrl}/summarise`;

export const createQuestionApi = `${baseUrl}/create_question`;

export const tokensApi = `${baseUrl}/tokens`;

export const plansApi = `${baseUrl}/plans`;

export const killQuestionApi = `${baseUrl}/kill_question`;
