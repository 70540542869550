import React, { useState, useEffect, useRef } from 'react';
import { FaArrowUp } from 'react-icons/fa';
import { FiEdit2, FiPlus, FiCopy } from 'react-icons/fi';
import { useSelector, useDispatch } from 'react-redux';
import { chatMessages } from '../features/chatMessagesSlice';
import { questionMessages } from '../features/questionMessagesSlice';
import { questionMessagesEdit } from '../features/questionMessagesEditSlice';
import { prompt } from '../features/promptSlice';
import { setIsQuestion } from '../features/isQuestionSlice';
import { kill } from '../features/killSlice'
import { createQuestion } from '../features/createQuestionSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { killQuestion } from '../features/killQuestionSlice';

const Chat = () => {
  const dispatch = useDispatch();
  const [input, setInput] = useState('');
  const messagesEndRef = useRef(null);

  const [chats, setChats] = useState([]);
  const [editedChat, setEditedChat] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const [selectedChatId, setSelectedChatId] = useState('current');

  const [showOptions, setShowOptions] = useState(false);

  const [responseMessages, setResponseMessages] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false)

  const isQuestion = useSelector(state => state.isQuestion);

  const [chatTitle, setChatTitle] = useState('')

  const handleEditClick = (index) => {
    if (chats?.[index]?.name) {
      setEditIndex(index);
      setEditedChat(chats[index].name);
    }
  };

  const handleSaveClick = async ({ id, name }) => {
    try {
      if (!name || typeof name !== 'string') {
        throw new Error('Invalid name provided');
      }
      
      const updatedChats = [...chats];
      const chatIndex = updatedChats.findIndex(chat => chat.id === id);
      
      if (chatIndex !== -1) {
        updatedChats[chatIndex] = {
          ...updatedChats[chatIndex],
          name: name
        };
        setChats(updatedChats);
        setEditIndex(null);
        setEditedChat('');
        await dispatch(questionMessagesEdit({ id, name }));
      }
    } catch (error) {
      console.error('Failed to save chat name:', error);
      setChats([...chats]);
    }
  };

  const { data: chatMessagesResponse } = useSelector(state => state.chatMessages) || {};
  const { data: questionMessagesResponse } = useSelector(state => state.questionMessages) || {};


  const [aliceBusy, setAliceBusy] = useState(false);

  useEffect(() => {
    // Initial poll of both types to establish state
    const pollBoth = async () => {
      await Promise.all([
        dispatch(chatMessages()),
        dispatch(questionMessages())
      ]);
    };

    // Active type polling
    const pollActive = () => {
      if (isQuestion) {
        dispatch(questionMessages());
      } else {
        dispatch(chatMessages());
      }
    };

    // Initial poll
    pollBoth();

    // Set up interval for active type only
    const intervalId = setInterval(pollActive, 2000);

    // Cleanup
    return () => {
      clearInterval(intervalId);
    };
  }, [dispatch, isQuestion]);

  useEffect(() => {
    const newBusyState = isQuestion ? 
      questionMessagesResponse?.status?.question_busy : 
      chatMessagesResponse?.status?.busy;
      
    setAliceBusy(!!newBusyState);
  }, [
    questionMessagesResponse?.status?.question_busy,
    chatMessagesResponse?.status?.busy,
    isQuestion
  ]);

  useEffect(() => {
    setChats(questionMessagesResponse?.conversations);
    if(selectedChatId === 'current'){
      setResponseMessages(chatMessagesResponse?.history);
    }else{
      const { messages } = (chats || []).filter(chat => chat.id === selectedChatId)?.[0] || {};
      setResponseMessages(messages || []);
    }
    scrollToBottom();
  }, [questionMessagesResponse?.conversations, chatMessagesResponse?.history, isQuestion, 
    selectedChatId, questionMessagesResponse?.status?.question_busy, chatMessagesResponse?.status?.busy, chats]);

  const loading = false

    useEffect(() => {
    scrollToBottom();
  }, [responseMessages]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView();
    }
  };

  const handleSendMessage = () => {
    if ((input || '').trim()) {
      dispatch(prompt({ input, id: selectedChatId }));
      setInput('');
    }
  };

  const handleChangeChats = (chatId, showOptionsVal = false) => {
    setSelectedChatId(chatId);

    if (chatId === 'current') {
      dispatch(setIsQuestion(false))
      setResponseMessages(chatMessagesResponse?.history);
    } else {
      dispatch(setIsQuestion(true))
      const { messages } = (chats || []).filter(chat => chat.id === chatId)?.[0] || {};
      setResponseMessages(messages || []);
    }
    setShowOptions(showOptionsVal);
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const dateString = date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
    const timeString = date.toLocaleTimeString(undefined, {
      hour: 'numeric',
      minute: '2-digit'
    });
    return `${dateString} ${timeString}`;
  };

  const [killDone, setKillDone] = useState(false)

  const handleKill = () => {
    if (isQuestion) {
      dispatch(killQuestion());
    } else {
      dispatch(kill());
    }
    setKillDone(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleChatTitle = (event) => {
    event.preventDefault();
    dispatch(createQuestion({name: chatTitle}))
    setIsModalOpen(false)
    setChatTitle('')
  }

  const handleTextCopy = () => {
    navigator.clipboard.writeText(JSON.stringify(responseMessages))
  }

  const handleOpenHtmlInNewWindow = (htmlContent) => {
    const newWindow = window.open();
    newWindow.document.write(htmlContent);
    newWindow.document.close();
  };

  useEffect(() => {
    // Poll both types when switching chat types
    const initializeChat = async () => {
      await Promise.all([
        dispatch(chatMessages()),
        dispatch(questionMessages())
      ]);
    };

    initializeChat();
  }, [selectedChatId, dispatch]); // Added dispatch to dependencies

  return (
    <div className="flex flex-col">
      {(
        <div className="bg-background-light p-2 flex items-center justify-between">
          <div className="flex items-center overflow-x-auto">
              {showOptions && 
              <>
              <button
              className="p-2 rounded mr-2 bg-background shadow cursor-pointer hover:bg-background-lighter transition-colors duration-200"
              onClick={() => {setIsModalOpen(true)}}
              >
                <FiPlus size={20} />
              </button>
              <button
                className="bg-secondary hover:bg-secondary-dark text-text font-bold py-2 px-4 mx-2 rounded transition-colors duration-200"
                onClick={()=>{handleTextCopy()}}
              >
                <>
                  <FiCopy className="inline-block mr-1"/> Copy
                </>
              </button>
              </>
              }
            {showOptions && (chats || []).map((chat, index) => (
              <div
                key={index}
                className={`p-2 rounded mr-2 cursor-pointer ${selectedChatId === chat.id ? 'bg-primary text-text' : 'bg-background text-text shadow'} transition-colors duration-200`}
                onClick={() => handleChangeChats(chat?.id, true)}
              >
                {index === editIndex ? (
                  <div>
                    <input
                      type="text"
                      value={editedChat}
                      onChange={(e) => setEditedChat(e.target.value.trim())}
                      className="mr-2 bg-background-light text-text"
                      minLength={1}
                      required
                    />
                    <button
                      onClick={() => { handleSaveClick({ id: chat.id, name: editedChat }) }}
                      className="bg-primary text-text p-1 rounded hover:bg-primary-dark transition-colors duration-200"
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <div className="flex items-center">
                    {chat?.name}
                    <FiEdit2
                      onClick={() => handleEditClick(index)}
                      className="ml-2 cursor-pointer"
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="flex">
            <div
              className={`p-2 rounded mr-2 cursor-pointer ${selectedChatId === 'current' ? 'bg-primary text-text' : 'bg-background text-text shadow'} transition-colors duration-200`}
              onClick={() => handleChangeChats('current')}
            >
              Current Chat
            </div>
            {
              <div className={`p-2 rounded mr-2 cursor-pointer ${selectedChatId !== 'current' ? 'bg-primary text-text' : 'bg-background text-text shadow'} transition-colors duration-200`}
                onClick={() => {
                  const showOptionsVal = true;
                  handleChangeChats(chats?.[0]?.id, showOptionsVal);
                }}
              >
                QnA
              </div>
            }
          </div>
        </div>
      )}

      <div className="flex-grow p-4 bg-background-lighter overflow-y-auto" style={{ height: '70vh' }}>
        {loading ? (
          <p>Loading...</p>
        ) : (
          (responseMessages || []).map((msg, index) => {
            const message = {
              text: msg.message,
              sender: msg.from === 'You' ? 'user' : 'alice',
              timestamp: msg.timestamp,
              id: index
            };

            return (
              <div
                key={message.id}
                className={`mb-2 p-2 rounded shadow max-w-md ${
                  message.sender === 'user' ? `${showOptions ? 'bg-secondary text-text' : 'bg-background text-text'} self-start` : 'bg-background-light text-text self-end'
                } transition-colors duration-200`}
                style={{ marginLeft: message.sender === 'alice' ? 'inherit' : 'auto' }}
              >
                <div className="text-xs text-text-muted mb-1">{formatTimestamp(message.timestamp)}</div>
                {selectedChatId !== 'current' && message.sender === 'alice' ? (
                  <button
                    onClick={() => handleOpenHtmlInNewWindow(message.text)}
                    className="bg-primary text-text font-bold py-2 px-4 rounded transition-colors duration-200"
                  >
                    View Answer
                  </button>
                ) : message.sender === 'alice' && message.text?.includes('<') ? (
                  <div dangerouslySetInnerHTML={{ __html: message.text }} />
                ) : (
                  (message.text || '').split('\n').map((line, index) => (
                    <div key={index}>{line}</div>
                  ))
                )}
              </div>
            );
          })
        )}
        <div ref={messagesEndRef} />
      </div>
      <div className="p-4 bg-background-light flex items-center justify-between">
        <input
          type="text"
          value={input}
          onChange={(e) => !aliceBusy && setInput(e.target.value)}
          className={`flex-grow p-2 border rounded mr-2 bg-background text-text border-background-lighter ${
            aliceBusy ? 'cursor-not-allowed opacity-60' : ''
          }`}
          placeholder={aliceBusy ? 'Alice is Busy' : 'Type a message...'}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !aliceBusy) {
              handleSendMessage();
            }
          }}
          disabled={aliceBusy}
          style={{ pointerEvents: aliceBusy ? 'none' : 'auto' }}
        />
        {aliceBusy && (
          <button
            onClick={() => handleKill()}
            className={`p-2 ${killDone ? 'bg-background-lighter' : 'bg-red-600'} text-text rounded flex-shrink-0 mr-2 transition-colors duration-200 hover:bg-red-700`}
          >
            Kill
          </button>
        )}
        <button
          onClick={() => !aliceBusy && handleSendMessage()}
          className={`p-2 ${aliceBusy ? 'bg-background-lighter cursor-not-allowed' : 'bg-primary hover:bg-primary-dark'} text-text rounded flex-shrink-0 transition-colors duration-200`}
          disabled={aliceBusy}
        >
          <FaArrowUp />
        </button>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 bg-background-lighter bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative bg-background p-4 rounded-lg shadow-lg text-text w-96">
            <button className="absolute top-2 right-2 text-text-muted hover:text-text" onClick={closeModal}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <h2 className="text-2xl font-bold mb-4">New Chat</h2>
            <form onSubmit={handleChatTitle}>
              <div className="mb-4">
                <label className="block text-text text-sm font-bold mb-2" htmlFor="chatTitle">
                  Chat Title
                </label>
                <input
                  type="text"
                  id="chatTitle"
                  name="chatTitle"
                  className="w-full px-3 py-2 border rounded-lg bg-background-light text-text border-background-lighter"
                  placeholder="Enter title"
                  value={chatTitle}
                  onChange={(event) => setChatTitle(event.target.value)}
                />
              </div>
              <div className="flex justify-center">
                <button type="submit" className="mt-4 bg-primary text-text px-4 py-2 rounded-lg hover:bg-primary-dark transition-colors duration-200">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chat;
