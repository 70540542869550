import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { tokensApi } from "../Api";

const RETRY_DELAY = 2000;
const MAX_RETRIES = 3;
const THROTTLE_DURATION = 5000;

const tokenSlice = createSlice({
  name: "tokens",
  initialState: {
    loading: false,
    data: null,
    error: "",
    lastFetchTimestamp: null,
    initialized: false
  },
  reducers: {
    tokenRequest(state) {
      state.loading = true;
      state.error = "";
    },
    tokenSuccess(state, action) {
      state.loading = false;
      state.data = {
        theaissist: action.payload.theaissist
      };
      state.error = "";
      state.lastFetchTimestamp = Date.now();
      state.initialized = true;
    },
    tokenFailure(state, action) {
      state.loading = false;
      state.data = null;
      state.error = action.payload;
      state.initialized = true;
    },
    resetTokenState(state) {
      state.loading = false;
      state.data = null;
      state.error = "";
      state.lastFetchTimestamp = null;
      state.initialized = false;
    }
  },
});

export const { tokenRequest, tokenSuccess, tokenFailure, resetTokenState } = tokenSlice.actions;

let currentRequest = null;

const fetchWithRetry = async (dispatch, signal, retryCount = 0) => {
  try {
    const token = localStorage.getItem("authToken");
    if (!token) {
      throw new Error("No auth token available");
    }

    if (currentRequest) {
      currentRequest.cancel();
    }

    const cancelToken = axios.CancelToken.source();
    currentRequest = cancelToken;

    const response = await axios.get(tokensApi, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: cancelToken.token,
      signal
    });

    if (signal?.aborted) return false;

    console.log('Token API Response:', response.data);
    dispatch(tokenSuccess(response.data));
    currentRequest = null;
    return true;
  } catch (error) {
    if (axios.isCancel(error) || signal?.aborted) {
      return false;
    }

    if (retryCount < MAX_RETRIES) {
      await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
      return fetchWithRetry(dispatch, signal, retryCount + 1);
    }

    dispatch(tokenFailure(error.message));
    return false;
  }
};

export const tokens = (force = false) => async (dispatch, getState) => {
  const state = getState().tokens;
  const now = Date.now();

  if (!force && state.lastFetchTimestamp && 
      now - state.lastFetchTimestamp < THROTTLE_DURATION) {
    return;
  }

  dispatch(tokenRequest());
  return await fetchWithRetry(dispatch);
};

export default tokenSlice.reducer;
