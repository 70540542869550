import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import { FaClipboardList, FaUserCircle, FaCopy, FaSignOutAlt, FaSpinner } from "react-icons/fa";
import Chat from "./Chat";
import { tokens } from "../features/tokenSlice";
import { getPlans } from "../features/getPlanSlice";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

const TokenDisplay = ({ data, loading, onCopy }) => {
  if (loading) {
    return (
      <div className="flex items-center">
        <div className="bg-background-lighter text-text font-medium px-4 py-2 rounded-lg shadow-md flex items-center">
          <FaSpinner className="animate-spin mr-2" />
          Loading credits...
        </div>
      </div>
    );
  }

  if (!data?.theaissist) {
    return (
      <div className="flex items-center">
        <div className="bg-background-lighter text-text font-medium px-4 py-2 rounded-lg shadow-md flex items-center">
          Unavailable
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center">
      <div className="bg-background-lighter text-text font-medium px-4 py-2 rounded-lg shadow-md flex items-center">
        <span>
          {`Compute Credits - ${Number(data.theaissist).toFixed(2)}`}
        </span>
        <button
          className="ml-2 bg-secondary text-text p-1 rounded-lg hover:bg-secondary-dark focus:outline-none focus:ring-2 focus:ring-secondary"
          onClick={() => onCopy(data.theaissist)}
          aria-label="Copy credits"
        >
          <FaCopy />
        </button>
      </div>
    </div>
  );
};

const Home = () => {
  const { data, loading, initialized } = useSelector((state) => state.tokens);
  const { error: errorForPlans } = useSelector((state) => state.getPlans);
  const { isAuthenticated } = useAuth0();
  const [retryCount, setRetryCount] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const controller = new AbortController();
    
    const fetchTokens = async () => {
      if (isAuthenticated && (!initialized || !data?.theaissist)) {
        const success = await dispatch(tokens(true));
        if (!success && retryCount < 3) {
          setTimeout(() => {
            setRetryCount(prev => prev + 1);
          }, 2000);
        }
      }
    };

    fetchTokens();

    const intervalId = setInterval(() => {
      if (isAuthenticated) {
        dispatch(tokens());
      }
    }, 30000); // Refresh every 30 seconds

    return () => {
      controller.abort();
      clearInterval(intervalId);
    };
  }, [dispatch, isAuthenticated, initialized, retryCount, data?.theaissist]);

  const copyToken = (token) => {
    navigator.clipboard.writeText(token).then(
      () => alert("Token copied to clipboard!"),
      (err) => alert("Failed to copy token:", err)
    );
  };

  const handlePlans = () => {
    dispatch(getPlans());
    if (!errorForPlans) {
      navigate("/plans");
    }
  };

  return (
    <div className="flex h-screen bg-background text-text">
      <Navbar />
      <div className="flex-grow flex flex-col">
        <header className="flex justify-between items-center p-4 bg-background-light">
          <div className="flex items-center space-x-4">
            <button
              className="flex items-center bg-primary text-text font-semibold px-4 py-2 rounded-lg shadow-md transition-all duration-300 hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary"
              onClick={handlePlans}
            >
              <FaClipboardList className="mr-2" />
              Plans
            </button>
          </div>

          <TokenDisplay data={data} loading={loading} onCopy={copyToken} />

          <div className="flex items-center space-x-4">
            <button
              onClick={() => navigate('/logout')}
              className="flex items-center bg-red-600 text-white font-semibold px-4 py-2 rounded-lg shadow-md hover:bg-red-700 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-red-500"
            >
              <FaSignOutAlt className="mr-2" />
              Logout
            </button>
            <div className="flex items-center">
              <FaUserCircle className="h-8 w-8 text-primary" />
            </div>
          </div>
        </header>
        <main className="flex-grow p-4 overflow-auto">
          <Chat />
        </main>
      </div>
    </div>
  );
};

export default Home;
